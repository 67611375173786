<template>
  <div class="page">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div class="main-content">
      <div class="leave-message-wrap">
        <div>
          <div class="content-input-wrap">
            <textarea v-model="content" placeholder="写下你想说的话"></textarea>
          </div>
          <div class="author-input-wrap" style="display:none;">
            <!-- <input type="text" v-model="author" placeholder="------ 留言人" /> -->
            <van-radio-group
              v-model="authorType"
              direction="horizontal"
              style="justify-content: flex-end;"
            >
              <van-radio :name="0" checked-color="#f4c251">
                <span style="color:#fff;">匿名</span>
              </van-radio>
              <!-- <van-radio :name="1">微信昵称</van-radio> -->
            </van-radio-group>
          </div>
          <div class="leave-name-type-select">
            <div
              class="leave-name-type-select-item"
              :class="{'actived': leaveNameType==2}"
              @click="leaveNameType=2"
            >使用微信昵称发布</div>
            <div
              class="leave-name-type-select-item"
              :class="{'actived': leaveNameType==1}"
              @click="leaveNameType=1"
            >匿名发布</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="write-message-button" @click="submit">
          <div class="animation-block-1">
            <div class="text">发布到留言板</div>
          </div>
          <div class="animation-block-2">
            <img src="@/assets/images/border_top_right.png" />
          </div>
          <div class="animation-block-3"></div>
        </div>
        <div class="other-href" @click="toNextPage('/barrage-wall')">返回留言板</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      content: "",
      author: "匿名",
      authorType: 0,
      leaveNameType: 2,
    };
  },
  created() {},
  methods: {
    toNextPage(url) {
      this.$router.push(url);
    },
    async submit() {
      if (!this.content) {
        this.$toast("请写下你想说的话");
        return false;
      }
      if (!this.author) {
        this.$toast("请填写留言人");
        return false;
      }

      await api.createMessage({
        leave_name_type: this.leaveNameType,
        content: this.content,
      });
      this.$toast.success("留言成功！");
      this.$toast({
        type: "success",
        message: "留言成功！",
        onClose: () => {
          this.$toast({
            message:
              "记得常回来看看，有多少人为你点赞和评论支持吧~有可能锦鲤会为你加油哦！",
            duration: 4000,
            closeOnClick:true,
            closeOnClickOverlay: true
          });
        },
      });
      this.$router.push("/barrage-wall");
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }

  .main-content {
    position: absolute;
    top: 20px;
    right: 0;
    bottom: 20px;
    left: 0;
    z-index: 10;
    display: flex;
    flex-flow: column;
    .leave-message-wrap {
      flex: 1;
      position: relative;
      & > div {
        position: absolute;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
      }
      .content-input-wrap {
        border: 1px solid #91bdc5;
        margin: 0 20px;
        padding: 10px;
        flex: 1;
        box-sizing: border-box;
        textarea {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: transparent;
          border-style: none;
          color: #fff;
          &::placeholder {
            color: #91bdc5;
          }
        }
      }
      .author-input-wrap {
        // border: 1px solid #91bdc5;
        margin: 0 20px;
        padding: 10px;
        box-sizing: border-box;
        margin-top: 20px;
        text-align: right;
        input {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: transparent;
          border-style: none;
          text-align: right;
          color: #fff;
          &::placeholder {
            color: #91bdc5;
          }
        }
      }
      .leave-name-type-select {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .leave-name-type-select-item {
          padding: 8px 12px;
          border: 1px solid #fff;
          color: #fff;
          &.actived {
            background-color: #fff;
            border: 1px solid #fff;
            color: #127987;
          }
          & + .leave-name-type-select-item {
            margin-left: 20px;
          }
        }
      }
    }
    .footer {
      text-align: center;
      .write-message-button {
        width: 145px;
        height: 72px;
        margin: 10px auto;
        position: relative;

        .animation-block-1 {
          position: absolute;
          z-index: 13;
          width: 145px;
          height: 66px;
          line-height: 66px;
          top: 6px;
          left: 0px;
          text-align: center;
          animation: 2s ease 0.8s 1 normal both running fadeInUp;
          .text {
            font-family: Tahoma, Helvetica, Arial;
            font-size: 22px;
            color: rgb(7, 121, 137);
            font-weight: 700;
          }
        }
        .animation-block-2 {
          position: absolute;
          z-index: 12;
          width: 117px;
          height: 62.5px;
          top: 0px;
          left: 34px;
          text-align: left;
          animation: 2s ease 0.6s 1 normal both running rollInLeft;
          img {
            height: 100%;
          }
        }
        .animation-block-3 {
          position: absolute;
          z-index: 12;
          width: 145px;
          height: 62.5px;
          top: 6px;
          left: 0px;
          text-align: left;
          animation: 2s ease 0.6s 1 normal both running rollInRight;
          background-color: #f4c251;
        }
      }
      .other-href {
        font-size: 14px;
        color: rgb(255, 255, 255);
        text-decoration-line: underline;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        padding: 8px 16px;
        & + .other-href {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>